// Dependencies
import AOS from 'aos'
import { modal } from 'tingle.js'

AOS.init({
	offset: 200,
	duration: 1000,
	easing: 'ease-out-sine',
	delay: 50,
	once: true
})

const modal1 = new modal({
    footer: false,
    stickyFooter: false,
    closeMethods: ['button', 'escape'],
    closeLabel: 'Close',
	cssClass: ['tingle-modal--fullscreen'],
})

const modal2 = new modal({
    footer: false,
    stickyFooter: false,
    closeMethods: ['button', 'escape'],
    closeLabel: 'Close',
	cssClass: ['tingle-modal--fullscreen'],
})

const btn1 = document.querySelector('[data-legal-notice]')
const btn2 = document.querySelector('[data-data-privacy]')

btn1.addEventListener('click', function(e) {
	e.preventDefault()
	modal1.open()
})

btn2.addEventListener('click', function(e) {
	e.preventDefault()
	modal2.open()
})

modal1.setContent(document.querySelector('.tingle-content__legal-notice').innerHTML)
modal2.setContent(document.querySelector('.tingle-content__data-privacy').innerHTML)
